import { useEffect, useRef, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import DownloadIcon from '@mui/icons-material/Download'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { drawCoordsCard, PLAN_UTIL_VERSION } from '@app/utils/planUtil'

const width = 1024
const height = 638

// remove all  these coords and map  once  https://mishe.atlassian.net/browse/SCRUM-341 done
// nUKZozbnb1yxWTepxieU
const mccCords = [
  { value: '{{plan.planData.metaTitle}}', x: 225, y: 185, color: 'black' },
  { value: '{{plan.planData.group_id}}', x: 225, y: 230 },
  { value: '{{name}}', x: 225, y: 285, color: 'black' },
  { value: '{{plan.memberData.external_id}}', x: 225, y: 335 }, // must have external ID on all plans
  { value: '025060', x: 225, y: 385, color: 'black' }, // should be in plandesign
  { value: 'EMRX', x: 225, y: 430, color: 'black' }, // should be in plandesign
  { value: 'EMRX', x: 225, y: 485, color: 'black' }, // should be in plandesign
  { value: '(888) 460-1579', x: 225, y: 535, color: 'black' }, // should be in {{plan.planData.planContact.supportNumber}}
  { value: 'www.affirmedrx.com/MCC', x: 225, y: 585, color: 'black' },
  { value: '<b>Deductibles:</b>', x: 550, y: 330 },
  { value: '<b>MCC Zero-Cost Network:</b> Waived', x: 550, y: 355 }, // should be a dynamic value designed in plandesign
  {
    value:
      '<b>IN:</b> ${{plan.planData.planDesign.deductible.inLower}} / ${{plan.planData.planDesign.deductible.inHigher}} <b>OUT:</b> ${{plan.planData.planDesign.deductible.outLower}} / ${{plan.planData.planDesign.deductible.outHigher}}',
    x: 550,
    y: 380,
  },
  { value: '<b>Max Out Of Pockets:</b>', x: 550, y: 405 },
  {
    value:
      '<b>IN:</b> ${{plan.planData.planDesign.outOfPocketMax.inLower}} / ${{plan.planData.planDesign.outOfPocketMax.inHigher}} <b>OUT:</b> ${{plan.planData.planDesign.outOfPocketMax.outLower}} / ${{plan.planData.planDesign.outOfPocketMax.outHigher}}',
    x: 550,
    y: 430,
  },
  {
    value:
      '<b>Office Visit/Specialist:</b> {{plan.planData.planDesign.officeOrSpecialistVisit.copay}} Copay',
    x: 550,
    y: 455,
  },
  {
    value:
      '<b>Urgent Care:</b> ${{plan.planData.planDesign.urgentCare.copay}} Copay{{plan.planData.planDesign.urgentCare.deductible}}', // if deductive is false, this should be waived. plandesin should write waived or be null
    x: 550,
    y: 480,
  },
  {
    value:
      '<b>RX Copay:</b> Generic: ${{plan.planData.planDesign.prescriptionDrugs.generic}} / Preferred Brand: ${{plan.planData.planDesign.prescriptionDrugs.preferredBrand}} / Brand: ${{plan.planData.planDesign.prescriptionDrugs.brand}} / Specialty: ${{plan.planData.planDesign.prescriptionDrugs.specialist}}',
    x: 550,
    y: 505,
  },
  { value: '<b>Cost Plus Drugs:</b> $0', x: 550, y: 555 }, // does not exist in db cpdrugs. it should coem from {{plan.planData.planDesign.cpDrugs}}
]
//axCjZn0kpFNkxLwLd0
const dentalMCCCoords = [
  { value: '{{plan.planData.group_id}}', x: 180, y: 260, color: 'white' },
  { value: '{{name}}', x: 180, y: 320, color: 'black' },
  { value: '{{plan.memberData.external_id}}', x: 180, y: 365, color: 'white' },
  { value: '<b>Dental {{plan.planData.planType}} Plan:</b>', x: 563, y: 350, color: 'white' },
  {
    value:
      '<b>Dental Benefits:</b> {{plan.planData.planDesign.dentalBenefits.annualMax}} Annual Max / Preventative, Basic and Major Services {{plan.planData.planDesign.dentalBenefits.lifetimeOrthodontiaMax}} Lifetime Orthodontia Max',
    x: 563,
    y: 380,
  },
  {
    value:
      '<b>Deductible:</b> {{plan.planData.planDesign.dentalBenefits.deductible.individual}} / {{plan.planData.planDesign.dentalBenefits.deductible.family}} {{plan.planData.planDesign.dentalBenefits.deductible.waivedForPreventative}}',
    x: 563,
    y: 455,
  },
  { value: '<b>Coinsurances:</b>', x: 563, y: 480 },
  {
    value:
      '<b>Preventative:</b> {{plan.planData.planDesign.dentalBenefits.preventiveServices.coinsurance}}%',
    x: 563,
    y: 505,
  },
  {
    value: '<b>Basic:</b> {{plan.planData.planDesign.dentalBenefits.basicServices.coinsurance}}%',
    x: 563,
    y: 530,
  },
  {
    value: '<b>Major:</b> {{plan.planData.planDesign.dentalBenefits.majorServices.coinsurance}}%',
    x: 563,
    y: 555,
  },
  {
    value:
      '<b>Orthodontia:</b> {{plan.planData.planDesign.dentalBenefits.orthodontiaServices.coinsurance}}%',
    x: 563,
    y: 580,
  },
]
// qwGk5c8R6wfOvVjh6SJ7
const defaultCardCoords = [
  // Name (Top left)
  { value: '{{name}}', x: 46.08, y: 191.4, color: 'black' },

  // External ID and Group ID / planID (Middle left)
  { value: '{{plan.memberData.external_id}}', x: 194.56, y: 265, color: 'black' },
  { value: '{{plan.planData.group_id}}', x: 194.56, y: 300, color: 'black' }, // should this be on planData

  // Coverage details (Bottom left)
  {
    value:
      '{{plan.planData.planDesign.primaryCare.copay}}%/{{plan.planData.planDesign.primaryCare.coinsurance}}%',
    x: 225,
    y: 485,
    color: 'black',
  },
  {
    value:
      '{{plan.planData.planDesign.officeOrSpecialistVisit.copay}}% / {{plan.planData.planDesign.officeOrSpecialistVisit.coinsurance}}%',
    x: 225,
    y: 515,
    color: 'black',
  },
  {
    value:
      '{{plan.planData.planDesign.urgentCare.copay}}% / {{plan.planData.planDesign.urgentCare.coinsurance}}%',
    x: 225,
    y: 545,
    color: 'black',
  },
  {
    value:
      '{{plan.planData.planDesign.surgery.copay}}% / {{plan.planData.planDesign.surgery.coinsurance}}%',
    x: 225,
    y: 575,
    color: 'black',
  },

  // Effective Date (Right top)
  {
    value: '02/01/2025',
    // { value: '{{plan.planData.effectiveDate}}',
    x: 731.43,
    y: 267.96,
    color: 'black',
  }, // this should be a hard date. not a timestamp. its a date like a bday not a relative date

  // PCP Label (Right middle)
  { value: 'PCP', x: 620, y: 365, color: 'black' },

  // Pharmacy Info (Right bottom)
  {
    value: '{{plan.planData.planDesign.pharmacyInfo.bin}} ',
    x: 640,
    y: 485,
    color: 'black',
  },
  { value: '{{plan.planData.planDesign.pharmacyInfo.grp}}', x: 640, y: 515, color: 'black' },
  {
    value: '{{plan.planData.planDesign.pharmacyInfo.pcn}}',
    x: 640,
    y: 555,
    color: 'black',
  },
]

const tempPlanCoordMap = {
  mcchealthplan: mccCords,
  mccdentalplan: dentalMCCCoords,
  mishehealthplan: defaultCardCoords,
}

const PatientMemberCardRenderer = ({
  name,
  planID,
  cardBackground,
  cardTitle,
  plan,
  cardSource,
}) => {
  const canvasRef = useRef(null)
  const [hover, setHover] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    if (!cardBackground) return

    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')

    const img = new Image()
    img.crossOrigin = 'anonymous' // Set crossOrigin attribute
    img.onload = () => {
      ctx.drawImage(img, 0, 0, width, height)
      drawCoordsCard(
        ctx,
        {
          name,
          plan,
          width,
          height,
          planID,
        },
        cardSource?.coords ||
          tempPlanCoordMap[plan?.planData?.slug] ||
          tempPlanCoordMap.mishehealthplan, // remove all but coords once  https://mishe.atlassian.net/browse/SCRUM-341 done
      )
      setImageLoaded(true)
    }

    img.src = cardBackground
  }, [imageLoaded, cardBackground, name, plan, planID])

  const handleDownload = async () => {
    const canvas = canvasRef.current

    if (!canvas) {
      console.error('no canvas to download')
      return
    }

    try {
      const dataUrl = canvas.toDataURL('image/png')

      // Create a download link for the generated image
      const link = document.createElement('a')

      link.href = dataUrl
      link.download = `${cardTitle}.png`

      document.body.appendChild(link) // Append link to the DOM for Safari compatibility
      link.click() // Trigger the download
      document.body.removeChild(link) // Remove the link from the DOM
    } catch (error) {
      console.error('Error generating or downloading the image:', error)
    }
  }

  return (
    <Box
      ke={PLAN_UTIL_VERSION}
      position='relative'
      display='inline-block'
      id={`${cardTitle}_container`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {!imageLoaded && (
        <Box position='absolute' top='50%' left='50%' transform='translate(-50%, -50%)' zIndex={1}>
          <CircularProgress />
        </Box>
      )}
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        style={{ width: '100%', height: 'auto', display: 'block' }}
      />
      {hover && imageLoaded && (
        <Box
          position='absolute'
          top={0}
          left={0}
          width='100%'
          height='100%'
          display='flex'
          alignItems='center'
          justifyContent='center'
          bgcolor='rgba(0, 0, 0, 0.3)'
        >
          <IconButton onClick={handleDownload} color='primary' size='large'>
            <DownloadIcon size='large' fontSize='inherit' />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default PatientMemberCardRenderer
